<template>
  <PrintBlock
    v-if="order"
    :order="order"
    @buttonClick="goNext"
    @print="print"
  />
</template>

<script lang="ts">
import router from '@/features/core/router';
import { useOrder } from '@/features/picking';
import { defineComponent, onMounted, ref } from 'vue';

import { useOrderLabels } from '../composables';
import { PrinterDevice, zebraPrintError } from '../types';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { PrintBlock } from '../components';

export default defineComponent({
  name: 'ZebraLabelPrinter',
  components: { PrintBlock },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const { order, loadOrder } = useOrder();
    const { zplLabels } = useOrderLabels(order);

    const selectedPrinterDevice = ref<PrinterDevice | null>(null);
    const errorBrowserPrint = ref('');

    onMounted(async () => {
      await loadOrder(props.id);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: External Library which is imported via <script> in index.html
      // eslint-disable-next-line no-undef, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      BrowserPrint.getDefaultDevice(
        'printer',
        (device: PrinterDevice) => {
          selectedPrinterDevice.value = device;
          print();
        },
        () => {
          errorBrowserPrint.value = zebraPrintError;
          notificationPlugin.get().show({
            text: errorBrowserPrint.value,
            type: NotificationType.Error,
            duration: -1,
          });
        },
      );
    });

    const print = () => {
      if (selectedPrinterDevice.value) {
        selectedPrinterDevice.value.send(
          zplLabels.value,
          undefined,
          (error: string) => {
            notificationPlugin.get().show({
              text: error,
              type: NotificationType.Error,
              duration: -1,
            });
          },
        );
      } else {
        notificationPlugin.get().show({
          text: zebraPrintError,
          type: NotificationType.Error,
          duration: -1,
        });
      }
    };

    const goNext = () => {
      void router.get().push({
        name: 'complete-picking-order',
        params: {
          id: props.id,
        },
      });
    };

    return {
      order,
      zplLabels,
      errorBrowserPrint,
      selectedPrinterDevice,
      print,
      goNext,
    };
  },
});
</script>
